* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

@media only screen and (max-width: 1200px) {
  /*Tablets [601px -> 1200px]*/
  html {
    font-size: 62.5%;
  }
}

body {
  background-image: url('./assets/background.svg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  overflow-x: hidden;
  /* overflow: hidden; */
  /* background: #1b1b1b; */
  /* background: #a591e6; */
  /* background: #fdfdfd; */
  /* background: #515561; */
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.navbar {
  position: relative;
  z-index: 999;
}

.sticky-nav {
  position: fixed;
  top: 0;
  left: 0;
}

.adminBtn:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.userBtn:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.btn-container {
  display: grid;
  align-items: center;
  justify-content: center;
  width: 90vw;
  height: 90vh;

  margin: auto;
}


